import styled from "styled-components";

export const UserCard = styled.div`
  display: flex;
  border-radius: 5px;
  background-color: #fff;
  width: 100%;
  height: 110px;
  padding: 1.5rem;
  align-items: center;
  box-shadow: 0px 1px 5px 1px #6b6b6bcc;
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;
  position: relative;
`;

export const CompanyCard = styled(UserCard)`
  margin-bottom: 0;
  box-shadow: none;
  margin-top: 0;
  border-radius: 5px 5px 0px 0px;
`;

export const UserAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  background-color: #d6d6d6;
  box-shadow: 0px 1px 5px 1px #6b6b6bd9;
  margin-right: 1rem;
`;

export const UserName = styled.span`
  font-weight: 600;
  font-size: 1rem;
  width: 35%;
  padding: 0 10px;

  @media (max-width: 1200px) {
    font-size: 0.8rem;
  }
`;

export const CompanyName = styled.span`
  font-weight: 600;
  font-size: 1rem;
  width: fit-content;
  cursor: pointer;
  padding: 0 5px;

  @media (max-width: 1200px) {
    font-size: 0.8rem;
  }
`;

export const CompanyRazao = styled.span`
  font-size: 0.9rem;
`;

export const UserEmail = styled(UserName)`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 300;
  width: 30%;
  padding: 0 5px;

  @media (max-width: 1200px) {
    font-size: 11px;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-left: auto;
  border-left: ${props => props.border || "1px solid #D9D7D7"};
  height: 100%;
  width: ${props => props.width || "9%"};
  position: absolute;
  right: 0;

  button.add-credencial {
    font-size: 0.8rem;
    color: #fff;
    background-color: #3a566e;
    border: none;
    border-radius: 7px;
    font-weight: 600;
    padding: 0 8px;

    @media (max-width: 1200px) {
      font-size: 0.6rem;
    }
  }
`;

export const AddAction = styled.button`
  border-radius: 50%;
  background-color: #18a9b5;
  width: 45px;
  height: 45px;
  position: absolute;
  /* right: -17.5px; */
  right: 4.5px;
  top: -17.5px;
  color: #fff;
  border: 0;
  font-weight: 600;
  box-shadow: 0px 2px 4px 0.1px #00000073;
  font-size: 1.5rem;
  z-index: 221;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
`;

export const ShowCredentials = styled.button`
  position: absolute;
  bottom: -10px;
  right: 50%;
  background-color: ${props => (props.isActive ? "#3A566E" : "#fff")};
  border: 1px solid #3a566e;
  border-radius: 50%;
  color: ${props => (props.isActive ? "#fff" : "#3A566E")};
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 333;
  transition: 1s;

  :focus {
    outline: 0;
  }
`;

export const CompanyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;
  box-shadow: 0px 1px 5px 1px #6b6b6bcc;
  border-radius: 5px;
`;

export const CredentialsContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 0.5rem 2rem;
  color: #000;
  background-color: #f5f5f5;
  animation: ${props =>
    props.isActive ? "slideDownCredencial" : "slideUpCredencial"}
    1100ms;
  height: 100px;
  opacity: 1;
  overflow-x: auto;
  overflow-y: hidden;
  border-radius: 0px 0px 8px 8px;

  @keyframes slideDownCredencial {
    from {
      height: 0px;
      opacity: 0;
      padding: 0;
    }
    to {
      height: 100px;
      opacity: 1;
      padding: 0.5rem 2rem;
    }
  }

  @keyframes slideUpCredencial {
    from {
      height: 100px;
      opacity: 1;
      padding: 0.5rem 2rem;
    }
    to {
      height: 0;
      opacity: 0;
      padding: 0;
    }
  }
`;

export const SingleCredential = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin: 0 10px;
  font-size: 0.85rem;

  @media (max-width: 1200px) {
    font-size: 0.67rem;
  }

  b {
    align-items: center;
  }
`;

export const PasswordInput = styled.input`
  background: transparent;
  border: none;
`;

export const CustomInput = styled.div`
  position: relative;
  margin: 20px 0;
  text-align: center;
  overflow: unset !important;

  span {
    position: absolute;
    background-color: white;
    font-size: 12px;
    left: 12px;
    top: -8px;
    padding: 0 5px;
    color: #7e7e7e;
    z-index: 1;
  }

  input,
  .phone-input,
  .cnpj-input,
  .select-portal,
  .select-empresas,
  .select-users {
    width: 100%;
    border: 1px solid rgba(0,0,0,0.23);
    border-radius: 4px;
    padding: 8px 8px;
    font-size: 14px;
    min-height: 56px;

    option:checked {
      background-color: #3a566e;
      color: white;
    }

    :focus {
      outline: none;
      border-width: 1.5px;
    }
  }

  .select-empresas,
  .select-users {
    padding-top: 12px;
  }
`;

export const TabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 250px;

  @media (max-width: 1200px) {
    width: 200px;
  }
`;
