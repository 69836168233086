import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Trash, Eye, EyeOff, Users, Smartphone } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import WaveLogo from "../../../Images/logo_wavecode.png";
import ModalEditCompany from "../modalLateral/ModalEditCompany";
import ModalAddCredential from "../modalLateral/ModalAddCredential";
import ModalEditCredential from "../modalLateral/ModalEditCredential";
import { ReactTooltip } from "../../../globalStyles";
import { ModalConfirmacao } from "../../../Components/newModal/modalConfirmacao";
import { ModalValidacaoAdmin } from "../modalLateral/ModalValidacaoAdmin";
import { AvisoExclamacaoRow } from "../../../Components/Notifications/AvisoExclamacaoRow";

import * as S from "../Style";

export const Empresa = inject("StoreConfiguracaoEmpresas")(
  observer(({ empresa, StoreConfiguracaoEmpresas }) => {
    const {
      fecharModal,
      onChange,
      openValidacaoAdmin,
      validarExcluir,
      verificaAdmin
    } = StoreConfiguracaoEmpresas;
    return (
      <EmpresaComStore
        empresa={empresa}
        fecharModal={fecharModal}
        onChange={onChange}
        validarExcluir={validarExcluir}
        openValidacaoAdmin={openValidacaoAdmin}
        verificaAdmin={verificaAdmin}
      />
    );
  })
);

export const EmpresaComStore = ({
  empresa,
  fecharModal,
  onChange,
  validarExcluir,
  openValidacaoAdmin,
  verificaAdmin
}) => {
  const [showing, toggleShowing] = useState(false);
  const [animation, toggleAnimation] = useState(false);

  const handleShowing = () => {
    if (showing) {
      toggleAnimation(true);
      setTimeout(() => {
        toggleShowing(!showing);
        toggleAnimation(false);
      }, 1000);
    } else toggleShowing(!showing);
  };

  const { razao_social, phone, nome_fantasia, cnpj } = empresa;
  return (
    <S.CompanyContainer>
      <ReactTooltip effect='solid' />
      <S.CompanyCard>
        <ReactTooltip effect='solid' />
        <div style={{ width: "9%" }}>
          <S.UserAvatar>
            <img alt='wavecode-logo' src={WaveLogo} style={{ width: "100%" }} />
          </S.UserAvatar>
        </div>
        <div style={{ display: "flex", width: "80%", alignItems: "center" }}>
          <div className='d-flex flex-column' style={{ width: "30%" }}>
            {/* <S.CompanyName>{nome_fantasia}</S.CompanyName> */}
            <S.CompanyName
              data-tip={nome_fantasia}
              data-place='bottom'
              data-type='info'
              data-effect='solid'
            >
              {razao_social}
            </S.CompanyName>
          </div>
          <S.UserEmail>
            <FontAwesomeIcon icon='code' style={{ marginRight: "1rem" }} />
            {cnpj}
          </S.UserEmail>
          <S.UserEmail>
            {/* <FontAwesomeIcon icon="phone" className="mr-1" />  */}
            <Smartphone className='mr-1' style={{ width: "22px" }} />
            {phone}
          </S.UserEmail>
        </div>
        <S.ActionContainer
          width={window.innerWidth > 1200 ? "20%" : "25%"}
          border='none'
        >
          <ModalAddCredential id={empresa.id} />
          <ModalEditCompany empresa={empresa} />
          <ModalConfirmacao
            button={
              <Trash
                className='icon-scale'
                color='#981212'
                data-tip='EXCLUIR EMPRESA'
                data-place='bottom'
                data-type='error'
                data-effect='solid'
              />
            }
            title='EXCLUIR EMPRESA'
            message={`Tem certeza que deseja excluir a empresa ${empresa.nome_fantasia}?`}
            confirmAction={() => openValidacaoAdmin(empresa.id)}
          />
          <ModalValidacaoAdmin
            status={validarExcluir}
            confirmAction={() => verificaAdmin("excluirEmpresa", false)}
            onChange={onChange}
            closeModal={fecharModal}
          />
        </S.ActionContainer>
      </S.CompanyCard>
      <S.ShowCredentials
        isActive={showing}
        className='icon-scale-xs'
        data-tip={`${showing ? "ESCONDER" : "MOSTRAR"} CREDENCIAIS`}
        data-place='right'
        data-type='info'
        data-effect='solid'
        onClick={handleShowing}
      >
        <FontAwesomeIcon icon={`angle-double-${showing ? "up" : "down"}`} />
      </S.ShowCredentials>
      {showing && (
        <S.CredentialsContainer isActive={!animation}>
          {empresa.credenciais.length ? (
            empresa.credenciais.map(cred => (
              <Credencial id={empresa.id} credencial={cred} />
            ))
          ) : (
            <AvisoExclamacaoRow title='Nenhuma credencial cadastrada.' />
          )}
        </S.CredentialsContainer>
      )}
    </S.CompanyContainer>
  );
};

const Credencial = ({ credencial, id }) => {
  const [passwordShowing, togglePassword] = useState(false);

  const { password, login, portal_id } = credencial;
  const nomePortal = (() => {
    switch (portal_id) {
      case 9:
        return "Portal de Compras Públicas";
      case 5:
        return "ComprasNet";
      case 6:
        return "Licitações-e";
      case 877:
        return 'Portal BLL'
      case 930:
        return 'Portal BNC'
      default:
        return "";
    }
  })();

  return (
    <S.SingleCredential>
      <ReactTooltip effect='solid' />
      <b style={{ display: "flex" }}>
        <ModalEditCredential
          empresaId={id}
          credencial={{ ...credencial, nomePortal }}
        />
        {nomePortal}
      </b>
      <div className='d-flex align-items-center'>
        <Users width='14px' className='mr-2' />
        <span>{portal_id === 6 ? `J${login}` : login}</span>
      </div>
      <div className='d-flex'>
        {passwordShowing ? (
          <EyeOff
            className='icon-scale mr-2'
            width='14px'
            onClick={() => togglePassword(!passwordShowing)}
            data-tip='ESCONDER PASSWORD'
            data-place='bottom'
            data-type='warning'
            data-effect='solid'
          />
        ) : (
          <Eye
            className='icon-scale mr-2'
            width='14px'
            onClick={() => togglePassword(!passwordShowing)}
            data-tip='MOSTRAR PASSWORD'
            data-place='bottom'
            data-type='warning'
            data-effect='solid'
          />
        )}
        <S.PasswordInput
          type={passwordShowing ? "text" : "password"}
          value={password}
          disabled
        />
      </div>
    </S.SingleCredential>
  );
};

export default Empresa;
