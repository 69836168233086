import styled from 'styled-components';

export const FileInput = styled.input`
  display: none;
`;

export const InserLogoEmpresa = styled.label`
  border: none;
  background: green;
  border-radius: 7px;
  margin-top: 7px;
  font-size: 70%;
  padding: 1px 10px;
  color: white;
  font-weight: 600;
`;

export const Container = styled.div`
  margin: 10px;
  padding: 25px;
  height: 100%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 1px 1px 8px 0px #4646464d;
  animation: 1s fadeIn ease-in-out;
  position: relative;
  overflow: auto;

  @media (max-height: 780px) {
    /* min-height: calc(100vh - 145px); */

    .modal-body {
      min-height: calc(100vh - 150px) !important;
      height: calc(100vh - 150px) !important;
    }
  }

  .section-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .title {
      display: flex;
      flex-direction: column;
      align-self: flex-start;

      .main-title {
        font-weight: 700;
        font-size: 110%;
      }

      .sub-title {
        font-size: 80%;
        color: #aaaab3;
      }
    }

    .upload-company-image {
      display: flex;
      flex-direction: column;
      align-items: center;

      .wrapper-image {
        padding: 6px;
        border: 1px solid #e2e2e2;
        border-radius: 12px;
        position: relative;

        svg#remover-avatar-empresa {
          color: #e82e2e;
          position: absolute;
          bottom: -7px;
          left: -7px;
          width: 20px;
          
        }

        .spinner {
          animation: 2s rotateZ infinite;
          width: 50px;
          height: 50px;
          padding: 6px;
          font-size: 1rem;
          color: #131e2557;
        }

        @keyframes rotateZ {
          100% {transform: rotate(360deg)}
        }

        img {
          width: 50px;
          height: 50px;
        }
      }
    }
  }

  .section-cabecalho, .section-rodape {
    display: flex;
    flex-direction: column;

    .title {
      font-weight: 700;
      font-size: 75%;
      margin-bottom: 7px;
    }

    .input-razao-cnpj,
    .input-est-mun-fone-cel,
    .logradouro,
    .logradouro2,
    .logradouro3,
    .input-nome-rep-legal {
      display: flex;
    }

    .imprimir-dados-textarea {
      display: flex;
      flex-direction: column;

      div:first-child {
        margin-bottom: 0px;
      }
    }
  }

  .section-cabecalho {
    .title {
      display: flex;
      align-items: center;

      .checkbox-desabilitar-cabecalho {
        margin-left: 50px;
        display: flex;
        align-items: center;
      
        input {
          margin-right: 5px;
        }
        span {
          color: #8a8a8a;
          font-weight: 500;
        }
      }
    }
    
    .inputs {
      display: flex;
      flex-direction: column;
      opacity: 0;
      animation: 1s fadeIn forwards;
    }
  } 

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
  }

  ::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-image: -webkit-gradient(linear,
                      left bottom,
                      left top,
                      color-stop(0.44, rgb(128, 187, 224)),
                      color-stop(0.72, rgb(80, 132, 166)),
                      color-stop(0.86, rgb(95, 163, 204)   ));
  }


  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const InputCustomizado = styled.div`
  position: relative;
  margin: 5px 0;
  text-align: center;
  overflow: unset !important;
  margin-right: ${props => props.mRight ? '18px' : 0};
  width: ${props => props.width || 'auto'};
  transition: 150ms;

  span {
    position: absolute;
    background-color: white;
    font-size: 11px;
    left: 12px;
    top: -8px;
    padding: 0 5px;
    color: #7e7e7e;
    z-index: 1;
    transition: 150ms;
  }

  input:disabled {
    background: #efefef;
  }

  input,
  .phone-input,
  textarea,
  .cnpj-input,
  .select-portal,
  .select-empresas,
  .select-users {
    width: 100%;
    height: ${props => props.height ? props.height : '30px'};
    border: 1px solid #9d9d9d;
    border-radius: 7px;
    padding: 3px 8px;
    font-size: 12px;
    transition: 200ms;
    border-color: ${props => props.validationValues ? '#9d9d9d' : 'rgba(202,0,14,0.56)'};

    option:checked {
      background-color: #3a566e;
      color: white;
    }

    :focus {
      outline: none;
      border-color: #3a566e8f;
      /* border-width: 1.5px; */
      box-shadow: 1px 1px 3px #717070;
    }
  }

  :focus-within {
    span {
      font-weight: 700;
    }
  }

  textarea {
    resize: none;
  }

  .select-empresas,
  .select-users {
    padding-top: 12px;
  }
`;

export const AssinaturaImg = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: 10px;
  border: 1px solid #e2e2e2;
  border-radius: 12px;
  padding: 6px;
  
  svg#remover-assinatura {
    color: #e82e2e;
    position: absolute;
    bottom: -7px;
    left: -7px;
    width: 20px;
  }

  img {
    max-height: 120px;
    max-width: 200px;
    max-width: 280px;
    max-height: 200px;
  }
`;
