import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";

import DetalhesEdital from "./contexto/detalhes/DetalhesEdital";
import ItensEdital from "./contexto/itens/itensEdital";
import { useKeyPress } from "../../../UsefulComponents/useKeyPress";
import Spinner from "../../../UsefulComponents/Spinner";

import * as S from "./styles";

const Detalhes = inject(
  "DetalhesEditalStore",
  "MaquinaDeEstadosStore"
)(
  observer(({ DetalhesEditalStore, MaquinaDeEstadosStore }) => {
    const { getStatus } = MaquinaDeEstadosStore;
    return (
      <DetalhesComStore
        getStatus={getStatus}
        props={DetalhesEditalStore}
        {...DetalhesEditalStore}
      />
    );
  })
);

const DetalhesComStore = ({ props, getStatus }) => {
  const { consultarItens, isFetching, fetchingMore } = props;
  const [active, setActive] = useState(true);
  useEffect(() => {
    consultarItens();
  }, []);

  

  if (isFetching || fetchingMore) return <Spinner />;
  return (
    <S.DetalhesContainer data-id="modal" >
      <div className='abas'>
        <S.Tab
          active={active}
          onClick={() => setActive(true)}
          tabIndex={0}
          data-id='licitacao'
        >
          LICITAÇÃO
        </S.Tab>
        {props.itens.length ? (
          <S.Tab
            active={!active}
            onClick={() => setActive(false)}
            tabIndex={0}
            data-id='itens'
          >
            ITENS
          </S.Tab>
        ) : null}
      </div>

      {active ? <DetalhesEdital props={props} /> : <ItensEdital {...props} />}
    </S.DetalhesContainer>
  );
};

export default Detalhes;
